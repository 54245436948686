<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          <ion-img
            src="assets/logo-header-toolbar.svg"
            class="img-toolbar"
          ></ion-img>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" fullscreen="true">
      <h1>{{ $t("Hi {name},", { name: user.nombre }) }}</h1>
      <p>{{ $t("you should register your main address") }}:</p>
      <form @submit.prevent="submit">
        <div>
          <ion-item>
            <ion-label position="floating">{{ $t("Address") }}</ion-label>
            <ion-input
              v-model="address.direccion"
              @change="v$.direccion.$touch"
              type="text"
              name="direccion"
            />
          </ion-item>
          <div v-if="v$.direccion.$error" class="error">
            {{ $t("This field is required") }}
          </div>
        </div>
        <div>
          <ion-item>
            <ion-label position="floating">{{ $t("Post Code") }}</ion-label>
            <ion-input
              v-model="address.cp"
              @change="v$.cp.$touch"
              type="text"
              name="cp"
            />
          </ion-item>
          <div v-if="v$.cp.$error" class="error">
            {{ $t("This field is required") }}
          </div>
        </div>
        <div>
          <ion-item>
            <ion-label position="floating">{{ $t("City / Town") }}</ion-label>
            <ion-input
              v-model="address.poblacion"
              @change="v$.poblacion.$touch"
              type="text"
              name="poblacion"
            />
          </ion-item>
          <div v-if="v$.poblacion.$error" class="error">
            {{ $t("This field is required") }}
          </div>
        </div>
        <div>
          <ion-item>
            <ion-label position="floating">{{ $t("Region") }}</ion-label>
            <ion-input
              v-model="address.provincia"
              @change="v$.provincia.$touch"
              type="text"
              name="provincia"
            />
          </ion-item>
          <div v-if="v$.provincia.$error" class="error">
            {{ $t("This field is required") }}
          </div>
        </div>
        <div>
          <ion-item>
            <ion-label position="floating">{{ $t("Phone Number") }}</ion-label>
            <ion-input
              v-model="address.telefono"
              @change="v$.telefono.$touch"
              type="phone"
              name="telefono"
            />
          </ion-item>
          <div v-if="v$.telefono.$error" class="error">
            {{ $t("This field is required") }}
          </div>
        </div>
        <div>
          <ion-item>
            <ion-label position="floating">{{ $t("ID Number") }}</ion-label>
            <ion-input
              v-model="address.nif"
              @change="v$.nif.$touch"
              type="phone"
              name="nif"
            />
          </ion-item>
          <div v-if="v$.nif.$error" class="error">
            {{ $t("This field is required") }}
          </div>
        </div>
        <div class="ion-padding-top">
          <ion-button type="submit" expand="block">{{
            $t("Submit")
          }}</ion-button>
        </div>
        <ion-item lines="none"><hr /></ion-item>
        <ion-item lines="none" class="logout ion-padding ion-text-center">
          <a @click.prevent="logout">{{
            $t("I'm not {name}. Change user.", { name: user.nombre })
          }}</a>
        </ion-item>
      </form>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  alertController,
  menuController
} from "@ionic/vue";
import { reactive, computed, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

export default {
  name: "FulfillUserType",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonImg,
    IonItem,
    IonLabel,
    IonInput,
    IonButton
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const user = computed(() => store.state.auth.user);
    const { t } = useI18n();
    const address = reactive({
      nombre: user.value.nombre,
      apellidos: user.value.apellidos,
      email: user.value.email,
      telefono: "",
      nif: "",
      direccion: "",
      poblacion: "",
      provincia: "",
      cp: ""
    });
    const rules = computed(() => ({
      nombre: { required },
      apellidos: { required },
      email: { required },
      telefono: { required, minLength: minLength(9) },
      nif: { required },
      direccion: { required },
      poblacion: { required },
      provincia: { required },
      cp: { required, minLength: minLength(4) }
    }));
    const v$ = useVuelidate(rules, address);
    const submit = async () => {
      if (v$._value.$invalid) {
        const alert = await alertController.create({
          cssClass: "error-alert",
          header: t("Error"),
          message: t("Please check the data required"),
          buttons: [t("Ok")]
        });
        alert.present();
        return false;
      }
      if (await store.dispatch("auth/addAddress", address)) {
        router.push({ name: "Diary", replace: true });
      } else {
        const alert = await alertController.create({
          cssClass: "error-alert",
          header: t("Error"),
          message: t("There was an error updating your user"),
          buttons: [t("Ok")]
        });
        alert.present();
        return false;
      }
    };
    const logout = () => {
      store.dispatch("auth/logout").then(() => {
        menuController.close("start");
        nextTick(() => (window.location = "/login"));
      });
    };
    return {
      user,
      address,
      v$,
      submit,
      logout
    };
  }
};
</script>
<style scoped>
.logout {
  display: block;
  text-align: center;
  font-size: 0.8rem;
}
.logout a {
  display: block;
  text-align: center;
  width: 100%;
  text-decoration: underline;
}
</style>
